import { Action, createReducer, on } from '@ngrx/store';
import {
  loadCustomersSuccess,
  loadNavigationSuccess,
  loadProjectsSuccess,
  resetNavigation,
  updateAdminNavigation,
} from './actions';
import {
  CustomerState,
  State,
  initialCustomerState,
  initialState,
} from './state';

const _reducer = createReducer(
  initialState,
  on(loadNavigationSuccess, (state, { items }) => ({ ...state, items })),
  on(updateAdminNavigation, (state, { items }) => ({
    ...state,
    items: [...items],
  })),
  on(resetNavigation, () => initialState),
  on(loadProjectsSuccess, (state, { projects }) => ({ ...state, projects }))
);
const _customerReducer = createReducer(
  initialCustomerState,
  on(loadCustomersSuccess, (state, { customers }) => ({
    ...state,
    customers: [...customers],
  }))
);

export function customerReducer(
  state: CustomerState | undefined,
  action: Action
): CustomerState {
  return _customerReducer(state, action);
}

export function reducer(state: State | undefined, action: Action): State {
  return _reducer(state, action);
}
