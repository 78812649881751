import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, EMPTY, map, Observable, switchMap } from 'rxjs';

import { environment } from 'environments/environment';
import { ObjectService } from './object.service';
import { UserWorkspaceService } from './user-workspace.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  customerid = 'clivkh7ei0000tb2scg5tzq3z';
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    subjectId: 'clivl5cbj0002tb2s8vu3gs9q',
  });
  activeWorkspaceId = this._userWorkspaceService.getActiveWorkspaceId();

  constructor(
    private _httpClient: HttpClient,
    private _userWorkspaceService: UserWorkspaceService,
    private objectService: ObjectService
  ) {
    this._userWorkspaceService.activeWorkspace$.subscribe(workspace => {
      if (workspace && workspace.id) {
        this.activeWorkspaceId = workspace.id;
      }
    });
  }
  // Add project
  createProject(projectData: any): Observable<any> {
    return this._httpClient
      .post(
        `${environment.backendUrl}/v1/Workspace/${this.activeWorkspaceId}/is_component_of/project`,
        projectData,
        { withCredentials: true }
      )
      .pipe(
        switchMap(response => {
          // Correct ranks after project creation
          return this.objectService
            .correctRanks(
              'Project',
              this.activeWorkspaceId ? this.activeWorkspaceId : '',
              true
            )
            .pipe(
              // Pass both the creation response and the rank correction result downstream
              map(() => ({
                projectResponse: response,
              }))
            );
        }),
        catchError(error => {
          console.error('Error creating project or correcting ranks:', error);
          return EMPTY;
        })
      );
  }

  // Get all projects by user
  getallprojects(): Observable<any> {
    return this._httpClient.get(
      `${environment.backendUrl}/v1/list/ofRecent/project`,
      {
        withCredentials: true,
      }
    );
  }

  // Add sub project
  createSubProject(projectData: any, projectId: string): Observable<any> {
    return this._httpClient
      .post(
        `${environment.backendUrl}/v1/project/${projectId}/is_part_of/project`,
        projectData,
        { withCredentials: true }
      )
      .pipe(
        switchMap(() => {
          // Correct ranks after sub-project creation
          return this.objectService.correctRanks(
            'Project',
            this.activeWorkspaceId ? this.activeWorkspaceId : '',
            true
          );
        }),
        catchError(error => {
          console.error(
            'Error creating sub-project or correcting ranks:',
            error
          );
          return EMPTY;
        })
      );
  }

  getAllProjectsByUser(): Observable<any> {
    return this._httpClient.get(
      `${environment.backendUrl}/v1/list/ofRecent/project`,
      {
        withCredentials: true,
      }
    );
  }

  // Update project
  updateproject(projectData: any, projectId: string): Observable<any> {
    return this._httpClient.put(
      `${environment.backendUrl}/v1/project/${projectId}`,
      projectData,
      { withCredentials: true }
    );
  }

  // Get project and users (dynamic webservice )
  getProjects(query: any): Observable<any> {
    return this._httpClient.post<any>(
      `${environment.backendUrl}/v1/dynamic/tree`,
      query,
      { withCredentials: true }
    );
  }
  //get a project and the children of the project
  getprojectsAndchildren(): Observable<any> {
    const request = {
      roleType: 'Workspace',
      id: this.activeWorkspaceId,
      fields: ['id', 'name'],
      relations: [
        {
          relation: 'Project',
          fields: ['id', 'name', 'rank'],
          relations: [
            {
              relation: 'Project',
              fields: ['id', 'name', 'rank'],
              relations: [
                {
                  relation: 'Project',
                  fields: ['id', 'name', 'rank'],
                  relations: [
                    {
                      relation: 'Project',
                      fields: ['id', 'name', 'rank'],
                      relations: [
                        {
                          relation: 'Project',
                          fields: ['id', 'name', 'rank'],
                          relations: [
                            {
                              relation: 'Project',
                              fields: ['id', 'name', 'rank'],
                              relations: [
                                {
                                  relation: 'Project',
                                  fields: ['id', 'name', 'rank'],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    };
    return this._httpClient.post<any>(
      `${environment.backendUrl}/v1/dynamic/tree`,
      request,
      {
        withCredentials: true,
      }
    );
  }
  //get project
  getProject(projectID: any): Observable<any> {
    const request = {
      roleType: 'Project',
      id: projectID,
      fields: ['id', 'name', 'context'],
      relations: [
        {
          relation: 'users',
          fields: ['id', 'name', 'email', 'role'],
        },
      ],
    };
    return this._httpClient.post<any>(
      `${environment.backendUrl}/v1/dynamic/tree`,
      request,
      { withCredentials: true }
    );
  }

  getProjectName(projectID: any): Observable<any> {
    const request = {
      roleType: 'Project',
      id: projectID,
      fields: ['name'],
    };
    return this._httpClient.post<any>(
      `${environment.backendUrl}/v1/dynamic/tree`,
      request,
      { withCredentials: true }
    );
  }
}
