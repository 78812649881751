import { Injectable } from '@angular/core';
import { ProjectService } from '@excelway/services/project.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CommonService } from 'app/common-dialogs/common.services';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as NavigationActions from './actions';
import { selectAllItems } from './selectors';
import { State } from './state';

@Injectable()
export class SharedStoreEffects {
  constructor(
    private actions$: Actions,
    private store: Store<{ navigations: State }>,
    private _commonService: CommonService,
    private _projectService: ProjectService
  ) {}

  loadNavigation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NavigationActions.loadNavigation),
      switchMap(() =>
        this.store.select(selectAllItems).pipe(
          map(items => NavigationActions.loadNavigationSuccess({ items })),
          catchError((error: any) =>
            of(NavigationActions.loadNavigationFailure({ error }))
          )
        )
      )
    )
  );

  loadProjects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NavigationActions.loadProjects),
      switchMap(() =>
        this._projectService.getprojectsAndchildren().pipe(
          map(projects => NavigationActions.loadProjectsSuccess({ projects })),
          catchError((error: any) =>
            of(NavigationActions.loadProjectsFailure({ error }))
          )
        )
      )
    )
  );
}
