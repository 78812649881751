import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Configuration, FrontendApi, Identity, Session } from '@ory/client';
import { Observable, ReplaySubject } from 'rxjs';

import { AuthStoreActions } from 'app/store/auth';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  config = new Configuration({
    basePath: environment.kratosUrl,
    baseOptions: {
      withCredentials: true,
    },
  });

  frontendApi = new FrontendApi(this.config);

  private _cache: any = null;
  private _identity: ReplaySubject<Identity> = new ReplaySubject<Identity>(1);

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for identity
   *
   * @param value
   */
  set identity(value: Identity) {
    // Store the value
    this._identity.next(value);
  }

  get identity$(): Observable<Identity> {
    return this._identity.asObservable();
  }

  /**
   * Constructor
   */
  constructor(private readonly _store: Store) {
    // Subscribe to identity changes
    this.identity$.subscribe((identity: Identity) => {
      // Build user from Kratos identity object
      const user = {
        id: identity?.id as string,
        email: identity?.traits?.email as string,
        name: `${identity?.traits?.name?.first} ${identity?.traits?.name?.last}` as string,
        language: `${identity?.traits?.language}` as string,
        avatar: null as any,
        status: 'online',
      };
      // Dispatch current user to auth store
      this._store.dispatch(AuthStoreActions.updateCurrentUser({ user }));
    });
  }

  /**
   * Get authenticated user
   */
  async getAuthUser(): Promise<Identity | null> {
    const session = await this.activeSession().catch(() => null);
    if (session) {
      this.identity = session.identity;
      return session.identity;
    }
    return null;
  }

  /**
   * Init Logout flow
   */
  async initLogoutFlow(): Promise<string> {
    return await this.frontendApi
      .createBrowserLogoutFlow(
        {},
        {
          withCredentials: true,
        }
      )
      .then(response => {
        return response.data.logout_token;
      })
      .catch(error => {
        console.error(error.response);
        throw error;
      });
  }

  /**
   * Update Logout flow
   */
  async updateLogoutFlow(token: string): Promise<void> {
    await this.frontendApi
      .updateLogoutFlow(
        {
          token,
        },
        {
          withCredentials: true,
        }
      )
      .catch(error => {
        console.error(error.response);
      });
  }

  /**
   * Perform logout
   */
  async logout(): Promise<void> {
    // Logout only when on production mode
    if (environment.production) {
      // Init logout flow to get token
      const token = await this.initLogoutFlow();
      // Logout given the session token
      this.updateLogoutFlow(token)
        .then(
          () =>
            // When signout is successfull redirect to login page
            // TODO make redirect to as url param
            (window.location.href = environment.loginUrl)
        )
        .catch(error => {
          console.log(error.response);
        });
    }
  }

  /**
   * Get active session
   */
  async activeSession(): Promise<Session> {
    return (
      await this.frontendApi.toSession(
        {},
        {
          withCredentials: true,
        }
      )
    ).data;
  }

  /**
   * Check the authentication status
   */
  async checkSession(): Promise<boolean | any> {
    const session: any = await this.activeSession().catch(() => {
      return {
        active: false,
      };
    });
    // If session is active, publish it's current user.
    if (session.active) {
      this.identity = (<Session>session).identity;
      localStorage.setItem(
        'isAccountVerified',
        session.identity.verifiable_addresses[0].verified
      );
      localStorage.setItem('email', session.identity.traits.email);
    }
    return session.active;
  }

  /**
   * Used only on local dev
   */
  async mockCheck(): Promise<boolean | any> {
    if (!this._cache) this._cache = this.generateUser();

    // mock current user.
    this.identity = this._cache;

    return true;
  }

  // Generate random user
  private generateUser(): Identity {
    const firstNames: string[] = ['Alice', 'Bob', 'Charlie', 'David', 'Eve'];
    const lastNames: string[] = ['Smith', 'Johnson', 'Lee', 'Homer'];

    const firstIndex = Math.floor(Math.random() * firstNames.length);
    const lastIndex = Math.floor(Math.random() * lastNames.length);

    const firstName = firstNames[firstIndex];
    const lastName = lastNames[lastIndex];

    return {
      id: `${firstName}.${lastName}`,
      schema_id: null as any,
      schema_url: null as any,
      traits: {
        email:
          `${firstName}-${lastName}-${getRandomNumber()}@bsuccess.net`.toLowerCase(),
        name: {
          first: firstName,
          last: lastName,
        },
      },
    };

    function getRandomNumber(min: number = 1, max: number = 100): number {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }
  }
}
