<div
  class="flex flex-col w-full h-full justify-start items-center pt-4 gap-y-2">
  <div class="md:w-11/12 w-3/4 h-14 flex justify-between items-center">
    <div class="relative flex w-full flex-wrap items-stretch w-60">
      <input
        type="search"
        placeholder="Enter an interface name ..."
        aria-label="Search"
        aria-describedby="button-addon1"
        class="relative m-0 -mr-0 block w-44 flex-auto border-2 border-neutral-50 bg-white bg-clip-padding px-1.5 py-2 text-base font-normal text-neutral-700 rounded-l-md" />
      <!--Search button-->
      <button
        class="relative flex items-center bg-neutral-50 px-2 py-1.5 rounded-r-md cursor-text"
        data-te-ripple-color="light">
        <mat-icon
          class="icon-size-5"
          [svgIcon]="'heroicons_solid:search'"></mat-icon>
      </button>
    </div>
    <button
      (click)="openCreateInterfaceDialog()"
      type="button"
      class="text-white bg-emerald-400 hover:bg-emerald-500 font-medium rounded text-md w-36 sm:w-36 px-5 h-10 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
      + New Interface
    </button>
  </div>

  <div
    class="relative max-h-120 overflow-auto shadow-md sm:rounded-lg w-3/4 md:w-11/12">
    <form [formGroup]="editForm">
      <table class="min-w-full divide-y divide-gray-300">
        <thead class="bg-gray-50">
          <tr>
            <th
              scope="col"
              class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
              Id
            </th>
            <th
              scope="col"
              class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
              Interface Name
            </th>
            <th
              scope="col"
              class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Interface Description
            </th>
            <th
              scope="col"
              class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Path
            </th>
            <th
              scope="col"
              class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              App Service Module
            </th>
            <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
              <span class="sr-only">Edit / Access</span>
            </th>
          </tr>
        </thead>
        <tbody class="divide-y divide-gray-200 bg-white">
          <tr
            *ngFor="let interfaceGroup of editForm.controls; let i = index"
            [formGroup]="interfaceGroup">
            <td
              class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
              <input
                [disabled]="true"
                formControlName="id"
                class="block p-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6" />
            </td>
            <td
              class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
              <input
                formControlName="name"
                class="block p-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6" />
            </td>
            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              <input
                formControlName="description"
                class="block p-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6" />
            </td>
            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              <input
                formControlName="path"
                class="block p-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6" />
            </td>
            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              <input
                formControlName="appServiceName"
                class="block p-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6" />
            </td>
            <td
              class="flex flex-row items-center gap-x-2 relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
              <button
                type="button"
                *ngIf="!interfaceGroup.get('isEditing').value"
                (click)="enableEdit(i)"
                class="text-indigo-600 hover:text-indigo-900">
                Edit<span class="sr-only"
                  >, {{ interfaceGroup.value.name }}</span
                >
              </button>
              <button
                type="button"
                *ngIf="interfaceGroup.get('isEditing').value"
                (click)="saveEdit(i)"
                class="text-green-600 hover:text-green-900">
                update
              </button>
              <button
                type="button"
                *ngIf="interfaceGroup.get('isEditing').value"
                (click)="cancelEdit(i)"
                class="text-red-600 hover:text-red-900">
                Cancel
              </button>
              <a
                [routerLink]="[
                  '/user-interface',
                  interfaceGroup.get('id')?.value,
                  'fields'
                ]"
                class="text-indigo-600 hover:text-indigo-900">
                Access<span class="sr-only"
                  >, {{ interfaceGroup.value.name }}</span
                >
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  </div>
  <div class="w-3/4 md:w-11/12 flex flex-col items-end justify-end">
    <button
      (click)="loadMoreInterfaces()"
      class="text-white bg-emerald-400 hover:bg-emerald-500 font-medium rounded text-md w-36 sm:w-36 px-5 h-10 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
      Load More interfaces ...
    </button>
  </div>
</div>
