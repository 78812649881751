import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CreateResponse,
  CreatedChartWidgetResponse,
  CreatedNumberWidgetResponse,
  CreatedWidgetResponse,
} from '@excelway/models/api/create-response';
import { QueryModel } from '@excelway/models/api/query';
import {
  UpdateResponse,
  UpdatedWidgetResponse,
} from '@excelway/models/api/update-response';
import { BoardModel } from '@excelway/models/board/board.model';
import { WidgetData } from '@excelway/models/board/widget-data.type';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class BoardService {
  // board templates
  // headers = new HttpHeaders().set('subjectId', 'clivl5cbj0002tb2s8vu3gs9q');

  private _data: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(
    private _httpClient: HttpClient,
    private _apiService: ApiService
  ) {}

  /**
   * Getter for data
   */
  get data$(): Observable<any> {
    return this._data.asObservable();
  }

  createIdentity(email: string): Observable<any> {
    const body = { email: email };
    return this._httpClient.post(
      `${environment.kratosUpdaterUrl}/v1/createUser`,
      body
    );
  }

  getIdentities(email: string): Observable<any> {
    return this._httpClient.post<any>(
      `${environment.kratosUpdaterUrl}/v1/getIdentities`,
      {
        email: email,
      }
    );
  }

  getFormOfCard(boardTemp: string): any {
    if (boardTemp === 'taskBoard') {
      return this.formData;
    } else {
      return this.formData1;
    }
  }

  getAllBoardsByUser(): Observable<any> {
    return this._httpClient.get(
      `${environment.backendUrl}/v1/list/ofRecent/board`,
      {
        withCredentials: true,
      }
    );
  }

  getBoard(id: string): Observable<BoardModel> {
    const query: QueryModel = {
      roleType: 'Board',
      id,
      fields: [
        'id',
        'name',
        'enableCardCost',
        'enableCardDuration',
        'currency',
        'durationUnit',
        'isTemplate',
      ],
      relations: [
        {
          relation: 'users',
          fields: ['id', 'name', 'email'],
        },
        {
          relation: 'tag',
          fields: ['id', 'name', 'color'],
        },
        {
          relation: 'section',
          fields: ['id', 'name'],
          relations: [
            {
              relation: 'card',
              fields: [
                'id',
                'name',
                'parentId',
                'parentName',
                'startDate',
                'endDate',
                'responsible',
                'priority',
                'isCompleted',
              ],
              relations: [
                {
                  relation: 'users',
                  fields: ['id', 'name', 'email', 'role'],
                },
                {
                  relation: 'responsible',
                  fields: ['id', 'name', 'email', 'avatar'],
                },
                {
                  relation: 'members',
                  fields: ['id', 'name', 'email', 'avatar'],
                },
                {
                  relation: 'tag',
                  fields: ['id', 'name', 'color'],
                },
                {
                  relation: 'comment',
                  fields: ['id', 'name'],
                },
                {
                  relation: 'checklist',
                  fields: ['id', 'name'],
                  relations: [
                    {
                      relation: 'checkListItem',
                      fields: ['id', 'name', 'checked'],
                    },
                  ],
                },
                {
                  relation: 'card',
                  fields: ['id', 'name'],
                },
                {
                  relation: 'document',
                  fields: ['id'],
                },
              ],
            },
          ],
        },
      ],
    };
    return this._apiService.getObject<BoardModel>(query);
  }

  getMoreBoard(id: string): Observable<BoardModel> {
    const query: QueryModel = {
      roleType: 'Board',
      id,
      fields: ['id', 'name'],
      relations: [
        {
          relation: 'users',
          fields: ['id', 'name', 'email'],
        },
        {
          relation: 'tag',
          fields: ['id', 'name', 'color'],
        },
        {
          relation: 'Section',
          fields: ['id', 'name'],
          relations: [
            {
              relation: 'card',
              fields: [
                'id',
                'name',
                'parentId',
                'parentName',
                'startDate',
                'endDate',
                'priority',
                'isCompleted',
              ],
              relations: [
                {
                  relation: 'users',
                  fields: ['id', 'name', 'email', 'role'],
                },
                {
                  relation: 'responsible',
                  fields: ['id', 'name', 'email', 'avatar'],
                },
                {
                  relation: 'members',
                  fields: ['id', 'name', 'email', 'avatar'],
                },
                {
                  relation: 'tag',
                  fields: ['id', 'name', 'color'],
                },
                {
                  relation: 'comment',
                  fields: ['id', 'name'],
                },
                {
                  relation: 'checklist',
                  fields: ['id', 'name'],
                  relations: [
                    {
                      relation: 'checkListItem',
                      fields: ['id', 'name', 'checked'],
                    },
                  ],
                },
                {
                  relation: 'card',
                  fields: ['id', 'name'],
                },
              ],
            },
          ],
        },
      ],
    };
    return this._apiService.getObject<BoardModel>(query);
  }

  createSection(parentId: string, name: string): Observable<CreateResponse> {
    const payload = {
      parentRoleType: 'Board',
      parentId,
      relationship: 'is_component_of',
      roleType: 'Section',
      body: {
        name,
        description: '',
      },
    };
    return this._apiService.createObject(payload);
  }

  createCard(
    parentId: string,
    name: string,
    priority?: string,
    endDate?: string | null
  ): Observable<CreateResponse> {
    const payload = {
      parentRoleType: 'Section',
      parentId,
      relationship: 'is_component_of',
      roleType: 'Card',
      body: {
        name,
        description: '',
        priority: priority ? priority : 'NA',
        endDate: endDate,
        isCompleted: false,
      },
    };
    return this._apiService.createObject(payload);
  }

  updateCard(
    cardId: string,
    name: string,
    priority?: string,
    endDate?: string
  ): Observable<any> {
    const payload = {
      roleType: 'Card',
      objectId: cardId,
      body: {
        name,
        priority: priority,
        endDate: endDate,
      },
    };
    return this._apiService.updateObject(payload);
  }

  toggleCompleted(cardId: string, isCompleted: boolean): Observable<any> {
    const payload = {
      roleType: 'Card',
      objectId: cardId,
      body: {
        isCompleted: !isCompleted,
      },
    };
    return this._apiService.updateObject(payload);
  }

  moveCardToSection(cardId: string, selectedSection: string): Observable<any> {
    const payload = {
      roleType: 'Card',
      objectId: cardId,
      body: {
        description: '',
        parentId: selectedSection,
      },
    };
    return this._apiService.updateObject(payload);
  }

  reorderSection(sectionId: string, selectedSection: string): Observable<any> {
    const payload = {
      roleType: 'Section',
      objectId: 'sectionId',
      body: {
        description: '',
        parentId: selectedSection,
      },
    };
    return this._apiService.updateObject(payload);
  }

  renameSection(sectionId: string, name: string): Observable<UpdateResponse> {
    const payload = {
      roleType: 'Section',
      objectId: sectionId,
      body: {
        name,
      },
    };
    return this._apiService.updateObject(payload);
  }

  //TODO Change the api to create a widget
  createWidget(
    parentId: string,
    widgetData: WidgetData
  ): Observable<CreatedWidgetResponse> {
    const payload = {
      parentRoleType: 'Board',
      parentId,
      relationship: 'is_component_of',
      roleType: 'Widget',
      body: widgetData,
    };
    return this._apiService
      .createObject(payload)
      .pipe(map(response => response as unknown as CreatedWidgetResponse));
  }

  updateWidget(
    widgetId: string,
    boardId: string,
    updatedWidget: WidgetData
  ): Observable<UpdatedWidgetResponse> {
    return this._httpClient.put<UpdatedWidgetResponse>(
      `${environment.backendUrl}/v1/update/widget/${widgetId}/board/${boardId}`,
      updatedWidget,
      { withCredentials: true }
    );
  }

  removeWidget(widgetId: string): Observable<any> {
    return this._httpClient.delete(
      `${environment.backendUrl}/v1/widget/${widgetId}`,
      { withCredentials: true }
    );
  }

  getcard(request: string): Observable<any> {
    return this._httpClient.post<any>(
      `${environment.backendUrl}/v1/dynamic/tree`,
      request,
      { withCredentials: true }
    );
  }

  getWidgets(
    boardId: string
  ): Observable<(CreatedChartWidgetResponse | CreatedNumberWidgetResponse)[]> {
    return this._httpClient.get<any>(
      `${environment.backendUrl}/v1/dashboard/widgets/${boardId}`,

      { withCredentials: true }
    );
  }

  getTags(boardId: string): Observable<any> {
    const request = {
      roleType: 'Board',
      id: boardId,
      fields: ['id'],
      relations: [
        {
          relation: 'tag',
          fields: ['id', 'color', 'name'],
        },
      ],
    };
    return this._httpClient.post<any>(
      `${environment.backendUrl}/v1/dynamic/tree`,
      request,
      { withCredentials: true }
    );
  }

  updateObject(RoleType: any, idRoleType: any, data: any): Observable<any> {
    return this._httpClient.put(
      `${environment.backendUrl}/v1/${RoleType}/${idRoleType}`,
      data,
      { withCredentials: true }
    );
  }

  /**
   * Create RoleType
   */
  createObject(
    RoleTypeParent: string,
    idRoleTypeParent: string,
    data: any,
    RoleType: string,
    Relation: string
  ): any {
    return this._httpClient.post(
      `${environment.backendUrl}/v1/${RoleTypeParent}/${idRoleTypeParent}/${Relation}/${RoleType}`,
      data,
      { withCredentials: true }
    );
  }

  //Drop card
  //
  moveObject(
    oldRoleTypeParentId: string,
    newRoleTypeParent: string,
    newRoleTypeParentId: string,
    roleTypeToMove: string,
    roleTypeToMoveId: string
  ): Observable<any> {
    return this._httpClient.patch(
      `${environment.backendUrl}/v1/moveObject/${oldRoleTypeParentId}/${newRoleTypeParent}/${newRoleTypeParentId}/${roleTypeToMove}/${roleTypeToMoveId}`,
      { withCredentials: true }
    );
  }

  addBoard(projectId: string, board: any): Observable<any> {
    return this._httpClient.post(
      `http://localhost:3007/object/v1/project/${projectId}/is_component_of/board`,
      board,
      { withCredentials: true }
    );
  }
  moveCardToAnotherSection(
    oldRoleTypeParentId: string,
    newRoleTypeParent: string,
    newRoleTypeParentId: string,
    roleTypeToMoveId: string
  ): Observable<any> {
    const roleTypeToMove = 'Card';
    return this._apiService.moveObject(
      oldRoleTypeParentId,
      newRoleTypeParent,
      newRoleTypeParentId,
      roleTypeToMove,
      roleTypeToMoveId
    );
  }
  duplicateCard(
    parentRoleType: string,
    card: { objectToDuplicateId: string; objectParentId: string }[],
    parentId: string
  ): Observable<any> {
    return this._apiService.duplicateObject(parentRoleType, card, parentId);
  }

  formData: any = [
    {
      type: 'autocomplete',
      required: false,
      label: 'Owner',
      model: 'owner',
      className: '',
      name: 'autocomplete-1699436983866-0',
      access: false,
      requireValidOption: false,
    },
    {
      type: 'date',
      label: 'date',
      subtype: 'date',
      model: 'date',
      className: '',
      name: 'date-1699436986697-0',
      access: false,
      style: 'default',
    },
    {
      type: 'tags',
      required: false,
      label: 'Priority',
      model: 'Priority',
      className: '',
      name: 'Priority-1699436990047-0',
      access: false,
      multiple: false,
    },
    {
      type: 'autocomplete',
      required: false,
      label: 'Section',
      className: '',
      model: 'section',
      name: 'autocomplete-1699437377644-0',
      access: false,
      subtype: 'Select',
    },
    {
      type: 'tags',
      required: false,
      label: 'Tags',
      className: '',
      model: 'tags',
      name: 'Tags-1699437377365-0',
      access: false,
      subtype: 'panel-tags',
    },
    {
      type: 'autocomplete',
      required: false,
      label: 'Members',
      className: '',
      model: 'members',
      name: 'autocomplete-1699437377365-0',
      access: false,
      subtype: 'Select',
    },
    {
      type: 'autocomplete',
      required: false,
      label: 'Boards',
      className: '',
      model: 'boards',
      name: 'autocomplete-1699437377365-2',
      access: false,
      subtype: 'Select',
    },
    {
      type: 'textarea',
      required: false,
      label: 'Description',
      className: '',
      model: 'editor',
      name: 'textarea-1699437377365-2',
      access: false,
      subtype: 'textarea',
    },
    {
      type: 'table',
      required: false,
      label: 'Budgets',
      className: '',
      model: 'multipLine',
      name: 'table-1699437377365-2',
      access: false,
      column: ['Description', 'budget'],
      subtype: 'table',
    },
  ];
  formData1: any = [
    {
      type: 'autocomplete',
      required: false,
      label: 'Owner',
      model: 'owner',
      className: '',
      name: 'autocomplete-1699436983866-0',
      access: false,
      requireValidOption: false,
    },
    {
      type: 'date',
      label: 'date',
      subtype: 'date',
      model: 'date',
      className: '',
      name: 'date-1699436986697-0',
      access: false,
      style: 'default',
    },
    {
      type: 'tags',
      required: false,
      label: 'Priority',
      model: 'Priority',
      className: '',
      name: 'Priority-1699436990047-0',
      access: false,
      multiple: false,
    },
    {
      type: 'autocomplete',
      required: false,
      label: 'Section',
      className: '',
      model: 'section',
      name: 'autocomplete-1699437377644-0',
      access: false,
      subtype: 'Select',
    },
    {
      type: 'tags',
      required: false,
      label: 'Tags',
      className: '',
      model: 'tags',
      name: 'Tags-1699437377365-0',
      access: false,
      subtype: 'panel-tags',
    },
    {
      type: 'autocomplete',
      required: false,
      label: 'Members',
      className: '',
      model: 'members',
      name: 'autocomplete-1699437377365-0',
      access: false,
      subtype: 'Select',
    },
    {
      type: 'autocomplete',
      required: false,
      label: 'Boards',
      className: '',
      model: 'boards',
      name: 'autocomplete-1699437377365-2',
      access: false,
      subtype: 'Select',
    },
    {
      type: 'text',
      required: false,
      label: 'Durée estimée',
      className: '',
      model: 'duree',
      name: 'autocomplete-1699437377365-3',
      access: false,
      subtype: 'Select',
    },
    {
      type: 'text',
      required: false,
      label: 'Durée realise',
      className: '',
      model: 'duree',
      name: 'autocomplete-1699437377365-3',
      access: false,
      subtype: 'Select',
    },
    {
      type: 'text',
      required: false,
      label: 'budget estimée',
      className: '',
      model: 'duree',
      name: 'autocomplete-1699437377365-3',
      access: false,
      subtype: 'Select',
    },
    {
      type: 'text',
      required: false,
      label: 'budget realise',
      className: '',
      model: 'duree',
      name: 'autocomplete-1699437377365-3',
      access: false,
      subtype: 'Select',
    },
    {
      type: 'textarea',
      required: false,
      label: 'Description',
      className: '',
      model: 'editor',
      name: 'textarea-1699437377365-2',
      access: false,
    },
  ];
}
