import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from '@excelway/services/auth.service';
import { AutorizationService } from '@excelway/services/autorization.service';
import { ProjectService } from '@excelway/services/project.service';
import {
  FuseNavigationService,
  FuseVerticalNavigationComponent,
} from '@fuse/components/navigation';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { Store } from '@ngrx/store';
import { Project } from 'app/modules/projects/project-settings/models/project';
import { ProjectSettingsComponent } from 'app/modules/projects/project-settings/project-settings.component';
import { selectEntireState } from 'app/store/shared-store';
import { State } from 'app/store/shared-store/state';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'classic-layout',
  templateUrl: './classic.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class ClassicLayoutComponent implements OnInit, OnDestroy, OnChanges {
  shouldDisplayNavbar: boolean = false;
  isScreenSmall: boolean;
  projectID: string;
  projectName: string | null;
  project: Project;
  Id: string;
  @Input() currentProjectId;
  isAccountVerified: boolean;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  navItems: any;
  state$: Observable<State>;
  workspaceId: string | null;

  workspaces: any[] = [];
  role: string;

  constructor(
    private _router: Router,
    private _fuseMediaWatcherService: FuseMediaWatcherService,
    private _fuseNavigationService: FuseNavigationService,
    private projectService: ProjectService,
    public dialog: MatDialog,
    private store: Store<{ navigations: State }>,
    private _changeDetectorRef: ChangeDetectorRef,
    private authService: AuthService,
    private _permissionService: AutorizationService
  ) {}

  ngOnInit(): void {
    this.state$ = this.store.select(selectEntireState);
    this.state$.pipe(takeUntil(this._unsubscribeAll)).subscribe(state => {
      this.navItems = state;
      this._changeDetectorRef.detectChanges();
    });
    this.showVerificationBanner();
    if (this.currentProjectId) {
      this.getProject();
    }

    // Subscribe to media changes
    this._fuseMediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(({ matchingAliases }) => {
        this.isScreenSmall = !matchingAliases.includes('md');
      });
    // Get workspaceId from route parameters
    this.workspaceId = localStorage.getItem('context');
    this.getUserRole();
  }

  ngOnChanges(): void {
    this.getProject();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
  getUserRole = (): void => {
    const workspaceId = localStorage.getItem('activeWorkspaceId');
    if (workspaceId) {
      this.authService.getAuthUser().then(identity => {
        if (identity) {
          this._permissionService
            .getAccessLevel(workspaceId, identity.id)
            .subscribe(response => {
              this.role = response.role;

              // Remove the button property from this.navItems.items['Projects'] if role is not 'admin'
              if (this.role !== 'admin') {
                this.navItems.items = this.navItems.items.map(item => {
                  if (item.id === 'Projects') {
                    // Omitting the 'button' property completely
                    const { button, ...itemWithoutButton } = item;

                    // Use void operator to indicate button is intentionally unused
                    void button;

                    return itemWithoutButton;
                  }
                  return item;
                });
              }
            });
        }
      });
    }
  };

  receiveWorkspaceId(data: string): void {
    this.workspaceId = data;
    const fullPath = this._router.url;

    const targetPath = fullPath.includes('teams-list')
      ? `/admin/${this.workspaceId}/teams-list`
      : fullPath.includes('log')
      ? `/admin/${this.workspaceId}/log`
      : `/admin/${this.workspaceId}/members-list`;

    // Navigate only if the target path is different from the current path
    if (fullPath !== targetPath) {
      this._router.navigate([targetPath]);
    }
  }

  toggleNavigation(name: string): void {
    const navigation =
      this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(
        name
      );

    if (navigation) {
      navigation.toggle();
    }
  }

  showVerificationBanner(): void {
    const localStorageValue = localStorage.getItem('isAccountVerified');
    if (localStorageValue !== null) {
      this.isAccountVerified = JSON.parse(localStorageValue);
    }
  }

  isProjectPage(): boolean {
    const segments = this._router.url.split('/');
    return segments[1] === 'projects' && segments[2] === 'project';
  }

  isHoamePage(): boolean {
    const segments = this._router.url.split('/');
    return segments[1] === 'home';
  }

  isMyTasksPage(): boolean {
    const segments = this._router.url.split('/');
    return segments[1] === 'mytasks';
  }

  private getProject(): void {
    if (this.currentProjectId) {
      this.projectService
        .getProject(this.currentProjectId)
        .subscribe(response => {
          this.projectName = response.name;
          this.project = response;
        });
    }
  }

  openProjectSettings(): void {
    const dialogRef = this.dialog.open(ProjectSettingsComponent, {
      data: {
        id: this.currentProjectId,
        selectedPanel: 'object-details',
        context: this.project.context,
      },
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '88%',
      width: '88%',
    });

    dialogRef.afterClosed().subscribe(name => {
      if (name) {
        this.projectName = name;
      }
    });
    this._changeDetectorRef.detectChanges();
  }

  openProjectInvites(): void {
    this.dialog.open(ProjectSettingsComponent, {
      data: {
        id: this.currentProjectId,
        selectedPanel: 'access-invites',
        context: this.project.context,
      },
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '88%',
      width: '88%',
    });
  }
}
