export const environment = {
  production: true,
  backendUrl: 'https://api.excelway.org/object',
  realtimeUrl: 'https://realtime.excelway.org',
  attachmentUrl: 'https://api.excelway.org/attachment',
  loginUrl: 'https://selfservice.excelway.org/#/login',
  kratosUrl: 'https://kratos.excelway.org',
  appBaseUrl: 'https://app.excelway.org',
  verificationUrl: 'https://selfservice.excelway.org/#/email-verification',
  selfserviceUrl: 'https://selfservice.excelway.org/',
  kratosUpdaterUrl: 'https://api.excelway.org/kratos-updater',
  completeRegistrationUrl:
    'https://selfservice.excelway.org/#/complete-registration',
};
