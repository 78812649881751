<div class="flex flex-col justify-between h-full">
  <div class="flex flex-0 items-center justify-between">
    <div class="text-lg font-bold">
      Créer un {{ data.objectName.toLowerCase() }}
    </div>
    <button mat-icon-button [tabIndex]="-1"></button>
  </div>
  <mat-dialog-content class="h-fit mat-typography">
    <form
      [formGroup]="addObjectForm"
      action="#"
      method="POST"
      class="mx-auto px-20 py-8">
      <div class="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
        <div class="sm:col-span-2">
          <label
            for="first-name"
            class="sm:col-span-2 text-base font-semibold leading-6 text-gray-900"
            >Nom de l'{{ data.objectName }}</label
          >
          <div class="mt-2.5">
            <input
              formControlName="name"
              type="text"
              name="first-name"
              id="first-name"
              autocomplete="given-name"
              class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-base sm:leading-6" />
          </div>
        </div>
        <div class="sm:col-span-2">
          <label
            for="message"
            class="block text-base font-semibold leading-6 text-gray-900"
            >Description</label
          >
          <div class="mt-2.5">
            <textarea
              formControlName="description"
              name="message"
              id="message"
              rows="4"
              class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-base sm:leading-6"></textarea>
          </div>
        </div>
        <div>
          <label
            for="company"
            class="block text-base font-semibold leading-6 text-gray-900"
            >Date de début
          </label>

          <mat-form-field
            color="accent"
            class="block text-base font-semibold leading-6 text-gray-900">
            <input
              matInput
              formControlName="startDate"
              [matDatepicker]="picker1" />
            <mat-datepicker-toggle [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>
        </div>
        <div>
          <label
            for="company"
            class="block text-base font-semibold leading-6 text-gray-900"
            >Date de fin
          </label>

          <mat-form-field
            color="accent"
            class="block text-base font-semibold leading-6 text-gray-900">
            <input
              matInput
              [matDatepicker]="picker2"
              formControlName="endDate" />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2 color="primary"></mat-datepicker>
            <mat-hint
              *ngIf="
                addObjectForm.get('endDate')?.hasError('endDateInvalid') &&
                addObjectForm.get('endDate')?.dirty &&
                addObjectForm.value.endDate
              "
              ><span class="text-red-400 font-medium"
                >End Date must be larger than Start Date</span
              ></mat-hint
            >
          </mat-form-field>
        </div>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button
      class="btn-tertiary-hover border-slate-300 font-semibold border"
      mat-dialog-close>
      Annuler
    </button>
    <button
      class="btn-primary"
      [mat-dialog-close]="true"
      (click)="AddObject()"
      [disabled]="
        !addObjectForm.get('endDate')?.valid && data.isEndDateMandatory
      ">
      Enregistrer
    </button>
  </mat-dialog-actions>
</div>
