import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Item, Items } from 'app/modules/documents/documents.types';
import { environment } from 'environments/environment';
import {
  BehaviorSubject,
  Observable,
  Subject,
  map,
  of,
  switchMap,
  take,
  tap,
  throwError,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DocumentsService {
  // Private
  private _item: BehaviorSubject<Item | null> = new BehaviorSubject(null);
  private _items: BehaviorSubject<Items | null> = new BehaviorSubject(null);

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for items
   */
  get items$(): Observable<Items | null> {
    return this._items.asObservable();
  }

  private deleteFile = new Subject<string>();
  deleteFile$ = this.deleteFile.asObservable();

  deleteDocument(id: string): void {
    this.deleteFile.next(id);
  }

  /**
   * Getter for item
   */
  get item$(): Observable<Item | null> {
    return this._item.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ bSuccess methods
  // -----------------------------------------------------------------------------------------------------

  createDocument(
    objectId: any,
    file: File,
    additionalData: any
  ): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    Object.keys(additionalData).forEach(key => {
      formData.append(key, additionalData[key]);
    });
    console.log('additionalData', formData);
    return this._httpClient.post(
      `${environment.attachmentUrl}/Project/${objectId}/attached_to/Document`,
      formData,
      {
        withCredentials: true,
      }
    );
  }

  createObjectBackground(
    objectId: string,
    objectRoleType: string,
    file: File,
    additionalData: any
  ): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    Object.keys(additionalData).forEach(key => {
      formData.append(key, additionalData[key]);
    });
    return this._httpClient.post(
      `${environment.attachmentUrl}/upload-background/${objectRoleType}/${objectId}`,
      formData,
      {
        withCredentials: true,
      }
    );
  }

  getDocuments(query: any): Observable<any> {
    return this._httpClient.post<any>(
      `${environment.backendUrl}/v1/dynamic/tree`,
      query,
      { withCredentials: true }
    );
  }

  getDocument(fileId: any, roleTypeChild: string): Observable<any> {
    return this._httpClient.get(
      `${environment.attachmentUrl}/${fileId}?roleTypeChild=${roleTypeChild}`,
      { withCredentials: true }
    );
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get items
   */
  getItems(folderId: string | null | any = null): Observable<Item[]> {
    return this._httpClient
      .get<Items>('api/documents', { params: { folderId } })
      .pipe(
        tap((response: any) => {
          this._items.next(response);
        })
      );
  }

  /**
   * Get item by id
   */
  getItemById(id: string): Observable<Item> {
    return this._items.pipe(
      take(1),
      map((items: any) => {
        // Find within the folders and files
        const item =
          [...items.folders, ...items.files].find(value => value.id === id) ||
          null;

        // Update the item
        this._item.next(item);

        // Return the item
        return item;
      }),
      switchMap(item => {
        if (!item) {
          return throwError('Could not found the item with id of ' + id + '!');
        }

        return of(item);
      })
    );
  }
}
