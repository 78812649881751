import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CreatePayload } from '@excelway/models/api/create-payload';
import { CreateResponse } from '@excelway/models/api/create-response';
import { QueryModel } from '@excelway/models/api/query';
import { UpdatePayload } from '@excelway/models/api/update-payload';
import { UpdateResponse } from '@excelway/models/api/update-response';
import { environment } from 'environments/environment';

//TODO Remove this service since it will be replace by object.service
@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private _httpClient: HttpClient) {}

  /**
   * Get object and it's relations
   */
  getObject<T>(query: QueryModel): Observable<T> {
    return this._httpClient.post<T>(
      `${environment.backendUrl}/v1/dynamic/tree`,
      query,
      {
        withCredentials: true,
      }
    );
  }

  /**
   * Create object
   */
  createObject(payload: CreatePayload): Observable<CreateResponse> {
    return this._httpClient.post<CreateResponse>(
      `${environment.backendUrl}/v1/${payload.parentRoleType}/${payload.parentId}/${payload.relationship}/${payload.roleType}`,
      payload.body,
      { withCredentials: true }
    );
  }

  /**
   * Update object
   */
  updateObject(payload: UpdatePayload): Observable<UpdateResponse> {
    return this._httpClient.put<UpdateResponse>(
      `${environment.backendUrl}/v1/${payload.roleType}/${payload.objectId}`,
      payload.body,
      { withCredentials: true }
    );
  }
  /**
   * add Attachment  to Object
   */
  addAttachment(
    objectParent: string,
    objectId: string,
    file: any,
    attachmentDetails: {
      createdAt: string;
      size: string;
      documentType: string;
    }
  ): Observable<any> {
    const attachmentData: FormData = new FormData();
    attachmentData.append('file', file, file.name);

    Object.keys(attachmentDetails).forEach(key => {
      attachmentData.append(key, attachmentDetails[key]);
    });
    return this._httpClient.post(
      `${environment.attachmentUrl}/${objectParent}/${objectId}/attached_to/Document`,
      attachmentData,
      { withCredentials: true }
    );
  }

  /**
   * Get object and it's relations
   */
  getObjectMembers<T>(roleType: string, objectId: string): Observable<T> {
    return this._httpClient.get<T>(
      `${environment.backendUrl}/v1/${roleType}/${objectId}/users`,
      {
        withCredentials: true,
      }
    );
  }
  getObjectLogs<T>(
    objectId: string,
    objectRoleType: string,
    skip: number,
    take: number
  ): Observable<T> {
    return this._httpClient.get<T>(
      `${environment.backendUrl}/v2/logs/${objectRoleType}/${objectId}?skip=${skip}&take=${take}`,
      {
        withCredentials: true,
      }
    );
  }
  assignedTagToObject(
    tagId: string,
    roleTypeObject: string,
    roleTypeObjectId: string
  ): Observable<any> {
    return this._httpClient.post<any>(
      `${environment.backendUrl}/v1/assigned_tag/${roleTypeObject}`,
      { tagId: tagId, roleTypeObjectId: roleTypeObjectId },
      {
        withCredentials: true,
      }
    );
  }
  moveObject(
    oldRoleTypeParentId: string,
    newRoleTypeParent: string,
    newRoleTypeParentId: string,
    roleTypeToMove: string,
    roleTypeToMoveId: string
  ): Observable<any> {
    return this._httpClient.patch<any>(
      `${environment.backendUrl}/v1/moveObject/${oldRoleTypeParentId}/${newRoleTypeParent}/${newRoleTypeParentId}/${roleTypeToMove}/${roleTypeToMoveId}`,
      null,
      { withCredentials: true }
    );
  }

  moveObjectByIndex(
    oldRoleTypeParentId: string,
    newRoleTypeParent: string,
    newRoleTypeParentId: string,
    roleTypeToMove: string,
    roleTypeToMoveId: string,
    previousIndex: string,
    newIndex: string
  ): Observable<any> {
    return this._httpClient.patch<any>(
      `${environment.backendUrl}/v1/moveObjectByIndex/${oldRoleTypeParentId}/${newRoleTypeParent}/${newRoleTypeParentId}/${roleTypeToMove}/${roleTypeToMoveId}/${previousIndex}/${newIndex}`,
      null,
      { withCredentials: true }
    );
  }

  changeObjectRank(
    objectId: string,
    parentId: string,
    parentRoleType: string,
    newRank: number
  ): Observable<any> {
    return this._httpClient.post<any>(
      `${environment.backendUrl}/reorder/reorder-objects-same-parent`,
      {
        roleTypeName: parentRoleType,
        parentId: parentId,
        objectId: objectId,
        newRank: newRank,
      },
      { withCredentials: true }
    );
  }

  shareObject(
    newRoleTypeParent: string,
    newRoleTypeParentId: string,
    roleTypeToShare: string,
    roleTypeToShareId: string
  ): Observable<any> {
    return this._httpClient.post<any>(
      `${environment.backendUrl}/v1/shareObject/${newRoleTypeParent}/${newRoleTypeParentId}/${roleTypeToShare}/${roleTypeToShareId}`,
      null,
      { withCredentials: true }
    );
  }

  getObjectsRecent<T>(roleType: string): Observable<T> {
    return this._httpClient.get<T>(
      `${environment.backendUrl}/v1/list/ofRecent/${roleType}`,
      {
        withCredentials: true,
      }
    );
  }

  duplicateObject(
    newParentRoleType: string,
    object: { objectToDuplicateId: string; objectParentId: string }[],
    newParentId: string
  ): Observable<any> {
    return this._httpClient.post<any>(
      `${environment.backendUrl}/v1/duplicateObjects/${newParentRoleType}/${newParentId}`,
      object,
      { withCredentials: true }
    );
  }
  getAssignedObjects<T>(roleType: string): Observable<any> {
    return this._httpClient.get<T>(
      `${environment.backendUrl}/v1/list/assigned/${roleType}`,
      {
        withCredentials: true,
      }
    );
  }

  getAllLogs<T>(
    skip: number,
    take: number,
    workspaceId: string
  ): Observable<any> {
    return this._httpClient.get<T>(
      `${environment.backendUrl}/v1/admin/logs/${workspaceId}?skip=${skip}&take=${take}`,
      {
        withCredentials: true,
      }
    );
  }
}
